import React from 'react';
import { Link } from 'react-router-dom';
import frontendConfig from 'frontendConfig';

import HeaderImg from 'render/assets/media/auxiliary/startups-and-companies-converge--matchbox.jpg';

function PageNotFound() {

  return (
    <>
      <section className="inner-page-header">
        <img src={HeaderImg}/>
        <div className="container">
          <div className="section-title">
            <h1>404</h1>
            <h3>Page Not Found</h3>
          </div>
        </div>
      </section>

      <hr className="hr-between-sections"/>
      <hr className="hr-between-sections"/>

      <section>
        <div className="container text-center">
            <div className="flex-column gap-l align-center justify-center">
              <p>It seems the page you're looking for does not exist.</p>
              <Link className="btn btn-fill" to={frontendConfig.routes.APPLY}>Return to Homepage</Link>
            </div>
        </div>
      </section>

      <hr className="hr-between-sections"/>
      <hr className="hr-between-sections"/>
    </>
  );
  
}

export default PageNotFound
