









import frontendConfig from 'frontendConfig';
import React, { useState,useEffect } from 'react';
import { Link } from 'react-router-dom';

import Logo from 'render/assets/media/brand/logo-matchbox.svg';

function HeaderComponent() {

  const [openDropdown, setOpenDropdown] = useState(null);
  const [menuState, setMenuState] = useState('closed');

  const toggleDropdown = (dropdownId) => {
    if (openDropdown === dropdownId) {
      setOpenDropdown(null);
    } else {
      setOpenDropdown(dropdownId);
    }
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!event.target.closest('.dropdown-group')) {
        setOpenDropdown(null);
      }
    };

    window.addEventListener('click', handleOutsideClick);
    return () => {
      window.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  const handleResponsiveMenuButtonClick = () => {
    const newState = menuState === 'closed' ? 'open' : 'closed';
    setMenuState(newState);

    // Update the body element attribute
    document.body.setAttribute('responsive-menu-state', newState);
  }

  return (
    <>
      <header id="main-header">
        <div className="container">
          <div id="main-header--logo-container">
            <Link to={frontendConfig.routes.HOME}><img src={Logo} alt="" /></Link>
          </div>

          <button id="main-header--menu-btn" className="btn-s btn-square p-0" onClick={handleResponsiveMenuButtonClick}>
            <i className="material-symbols-outlined">menu</i>
          </button>

          <nav id="main-header--main-nav">
            <Link to={frontendConfig.routes.ABOUT}>About</Link>
            <div className="dropdown-group" onMouseEnter={() => toggleDropdown('nav1')} onMouseLeave={() => setOpenDropdown(null)}>
              <button type="button">Program</button>
              {openDropdown === 'nav1' && (
                <div className="dropdown" align="left">
                  <Link to={frontendConfig.routes.CHALLENGES} onClick={() => setOpenDropdown(null)}>Challenges</Link>
                  {/* <Link to={frontendConfig.routes.AGENDA} onClick={() => setOpenDropdown(null)}>Agenda</Link> */}
                  <Link to={frontendConfig.routes.ECOSYSTEM_PARTNERS} onClick={() => setOpenDropdown(null)}>Ecosystem Partners</Link>
                </div>
              )}
            </div>
            <Link to={frontendConfig.routes.COMPANIES}>Companies</Link>
            <Link to={frontendConfig.routes.STARTUPS}>Startups</Link>
            <Link to={frontendConfig.routes.APPLY}>Apply</Link>
            {/* <div className="dropdown-group" onMouseEnter={() => toggleDropdown('nav2')} onMouseLeave={() => setOpenDropdown(null)}>
              <button type="button">Apply</button>
              {openDropdown === 'nav2' && (
                <div className="dropdown" align="left">
                  <Link to={frontendConfig.routes.APPLY_COMPANIES} onClick={() => setOpenDropdown(null)}>Companies</Link>
                  <Link to={frontendConfig.routes.APPLY_STARTUPS} onClick={() => setOpenDropdown(null)}>Startups</Link>
                </div>
              )}
            </div> */}
          </nav>
        </div>
      </header>

      <div id="responsive-header">
        <button className="btn-s btn-ghost btn-square p-0" onClick={handleResponsiveMenuButtonClick}>
          <i className="material-symbols-outlined">clear</i>
        </button>

        <nav id="responsive-header--main-nav">
          <Link to={frontendConfig.routes.ABOUT} onClick={handleResponsiveMenuButtonClick}>About</Link>
          <Link to={frontendConfig.routes.CHALLENGES} onClick={handleResponsiveMenuButtonClick}>Challenges</Link>
          {/* <Link to={frontendConfig.routes.AGENDA} onClick={handleResponsiveMenuButtonClick}>Agenda</Link> */}
          <Link to={frontendConfig.routes.ECOSYSTEM_PARTNERS} onClick={handleResponsiveMenuButtonClick}>Ecosystem Partners</Link>
          <Link to={frontendConfig.routes.COMPANIES} onClick={handleResponsiveMenuButtonClick}>Companies</Link>
          <Link to={frontendConfig.routes.STARTUPS} onClick={handleResponsiveMenuButtonClick}>Startups</Link>
          <Link to={frontendConfig.routes.APPLY} onClick={handleResponsiveMenuButtonClick}>Apply</Link>
        </nav>
      </div>
    </>
  );

}

export default HeaderComponent
