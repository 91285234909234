import React from 'react';
import { BrowserRouter as Router, Route, useLocation } from 'react-router-dom';
import RoutesFile from 'routes/RoutesFile';
import HeaderComponent from 'render/views/components/HeaderComponent';
import FooterComponent from 'render/views/components/FooterComponent';
import { MainProvider } from 'providers/MainProvider';
import useScrollToTop from 'controller/hooks/useScrollToTop';
import { FullScreenOverlayProvider } from 'controller/hooks/useFullScreenOverlay';

function App() {
  useScrollToTop();
  const location = useLocation();

  return (
    <MainProvider>
      <FullScreenOverlayProvider>
        <main page={location.pathname.replace('/', '')}>
          <HeaderComponent />
          <RoutesFile />
          <FooterComponent />
        </main>
      </FullScreenOverlayProvider>
    </MainProvider>
  );
}

export default App;