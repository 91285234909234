import { asyncActions } from 'controller/BaseSlice';
import { useFullScreenOverlay } from 'controller/hooks/useFullScreenOverlay';
import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import HeaderImg from 'render/assets/media/auxiliary/apply--matchbox.jpg';

/* Startup Form Data */

const sales = [
  "0€",
  "< 100k€",
  "100k€ to 500K€",
  "500k€ to 1M€",
  "> 1M€",
]

const stage = [
  "Idea",
  "Pré-Seed",
  "Seed",
  "Scaleup",
]

const sector = [
  "Aerospace and Defense",
  "Agriculture and Agribusiness",
  "Automotive",
  "Chemical Industry",
  "Construction and Real Estate",
  "Consumer Goods",
  "Education",
  "Energy",
  "Environment and Sustainability",
  "Financial and Insurance",
  "Food and Beverage",
  "Healthcare and Pharmaceuticals",
  "Information Technology and Communication (ICT)",
  "Manufacturing",
  "Media and Entertainment",
  "Metallurgy and Mining",
  "Professional Services",
  "Retail and E-commerce",
  "Tourism and Hospitality",
  "Transportation and Logistics",
  "Other"
]

const initialStartupFormData = {
  name: '',
  city: '',
  sector: null,
  website: '',
  personEmail: '',
  personName: '',
  personRole: '',
  shortDescription: '',
  stage: null, 
  sales: null,
  challenges: '',
  investment: null,
  link: null
};

/* Company Form Data */

const initialCompanyFormData = {
  name: '',
  city: '',
  sector: sector[0],
  vat: '',
  personEmail: '',
  personName: '',
  personRole: '',
  personDepartment: '',
  secondName: '',
  secondEmail: '',
  challenges: '',
  startupPilot: '',
};

function ApplyPage() {

  const dispatch = useDispatch();
  const { showOverlay, hideOverlay } = useFullScreenOverlay();
  const [formStartupData, setStartupFormData] = useState(initialStartupFormData);
  const [formCompanyData, setCompanyFormData] = useState(initialCompanyFormData);
  const fileInputRef = useRef();
  const [showStartupForm, setShowStartupForm] = useState(true);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setStartupFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleCompanyInputChange = (event) => {
    const { name, value } = event.target;
    setCompanyFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleFileChange = (event) => {
    setStartupFormData(prevState => ({
      ...prevState,
      link: event.target.files[0]
    }));
  };

  const handleStartupApplication = async (startupApplication) => {
    const formData = new FormData();
    Object.entries(startupApplication).forEach(([key, value]) => {
      formData.append(key, value);
    });

    showOverlay();
    toast.promise(
      dispatch(asyncActions.startups.post(formData)).unwrap(),
      {
        pending: 'Submitting application...',
        success: 'Application successful!',
        error: 'Application failed!'
      }
    ).then(response => {
      // Handle the successful response here
      console.log('POST response:', response);
      // You can add additional logic here to process the response
  }).finally(() => {
      setStartupFormData(initialStartupFormData); 
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
      hideOverlay();
    });
  };

  const handleStartupSubmit = (event) => {
    event.preventDefault();
    handleStartupApplication(formStartupData);
  };

  const handleCompanyApplication = async (startupApplication) => {
    const formData = new FormData();
    Object.entries(startupApplication).forEach(([key, value]) => {
      formData.append(key, value);
    });

    showOverlay();
    toast.promise(
      dispatch(asyncActions.companies.post(formData)).unwrap(),
      {
        pending: 'Submitting application...',
        success: 'Application successful!',
        error: 'Application failed!'
      }
    ).finally(() => {
      setCompanyFormData(initialCompanyFormData);
      hideOverlay();
    });
  };

  const handleCompanySubmit = (event) => {
    event.preventDefault();
    handleCompanyApplication(formCompanyData);
  };

  const handleShowStartupForm = () => {
    setShowStartupForm(true);
  };

  const handleShowCompanyForm = () => {
    setShowStartupForm(false);
  };

  const createDropdown = (name, label, options) => (
    <>
      <label>{label}</label>
      <div className="custom-select">
        <select name={name} onChange={handleInputChange}>
          <option disabled selected>Select an option</option>
          {options.map(option => (
            <option key={option} value={option}>{option}</option>
          ))}
        </select>
        <svg className="chevron-down" xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" style={{ 'enableBackground': 'new 0 0 14 9' }} viewBox="0 0 14 9"><path d="M1.5 2.1 7.1 7l5.4-4.8" /></svg>
      </div>
    </>
  );

  return (
    <>
      <section className="inner-page-header">
        <img src={HeaderImg}/>
      </section>

      <hr className="hr-between-sections"/>

      <section>
        <div className="container">
          <div className="section-title">
            <div className="sub-title"><h3>Join the</h3></div>
            <h2>Innovation Journey:</h2>
            <h1 className="text-primary">Apply for Matchbox</h1>
            <p>Welcome to the gateway of innovation! Our program offers a dynamic platform for forward-thinking companies and visionary entrepreneurs to embark on a transformative journey. Are you ready to take the next step towards driving innovation, fostering collaboration, and unlocking growth opportunities? Apply now to be a part of our thriving ecosystem.</p>
          </div>
        </div>

      </section>

      <hr className="hr-between-sections"/>

      <section>
        <div className="container">  
          <h2 className="text-center">Apply as</h2>
          <hr className="hr-m"/>
          <div className="justify-center button-row">
            <button type="button" className={`btn ${showStartupForm ? 'btn-fill' : 'btn-ghost'}`} onClick={handleShowStartupForm}>Startup</button>
            <button type="button" className={`btn ${showStartupForm ? 'btn-ghost' : 'btn-fill'}`} onClick={handleShowCompanyForm}>Company</button>
          </div>
          <hr className="hr-xl"/>

          {showStartupForm ? (
            <form onSubmit={handleStartupSubmit} className="flex-column gap-column-l gap-row-m grid-2 grid-1-on-breakpoint-sm">
              <fieldset>
                <label htmlFor="name">Name</label>
                <input
                  placeholder="Name"
                  type="text"
                  id="name"
                  name="name"
                  value={formStartupData.name}
                  onChange={handleInputChange}
                  required
                />
              </fieldset>
              <fieldset>
                <label htmlFor="city">City of Incorporation</label>
                <input
                  placeholder="City of Incorporation"
                  type="text"
                  id="city"
                  name="city"
                  value={formStartupData.city}
                  onChange={handleInputChange}
                  required
                />
              </fieldset>
              <fieldset>{createDropdown('sector', 'Activity Sector', sector)}</fieldset>
              <fieldset>
                <label htmlFor="website">Website</label>
                <input
                  placeholder="Website"
                  type="text"
                  id="website"
                  name="website"
                  value={formStartupData.website}
                  onChange={handleInputChange}
                  required
                />
              </fieldset>
              <fieldset>
                <label htmlFor="personEmail">Key Person's Email</label>
                <input
                  placeholder="Key Person's Email"
                  type="email"
                  id="personEmail"
                  name="personEmail"
                  value={formStartupData.personEmail}
                  onChange={handleInputChange}
                  required
                />
              </fieldset>
              <fieldset>
                <label htmlFor="personName">Key Person Name</label>
                <input
                  placeholder="Key Person's Name"
                  type="text"
                  id="personName"
                  name="personName"
                  value={formStartupData.personName}
                  onChange={handleInputChange}
                  required
                />
              </fieldset>
              <fieldset>
                <label htmlFor="personRole">Key Person's Role</label>
                <input
                  placeholder="Key Person's Role"
                  type="text"
                  id="personRole"
                  name="personRole"
                  value={formStartupData.personRole}
                  onChange={handleInputChange}
                  required
                />
              </fieldset>
              <fieldset>
                <label>Short Business Description</label>
                <textarea
                  placeholder="Short Business Description"
                  id="shortDescription"
                  name="shortDescription"
                  value={formStartupData.shortDescription}
                  onChange={handleInputChange}
                  required
                />
              </fieldset>

              <fieldset>{createDropdown('stage', 'Startup Stage', stage)}</fieldset>
              <fieldset>{createDropdown('sales', 'Do you have any sales? How much is your ARR?', sales)}</fieldset>

              <fieldset>
                <label>Example pilot's results and challenges</label>
                <textarea
                  placeholder="Have you developed any pilot with a company partner? Please provide main results and challenges"
                  id="challenges"
                  name="challenges"
                  value={formStartupData.challenges}
                  onChange={handleInputChange}
                  required
                />
              </fieldset>

              <fieldset>{createDropdown('investment', 'Have you raised any investment or received any grants?', sales)}</fieldset>

              <fieldset>
                <label htmlFor="link">Pitch Deck</label>
                <input 
                  type="file" 
                  id="link" 
                  name="link" 
                  onChange={handleFileChange} 
                  ref={fileInputRef} 
                  required />
              </fieldset>

              <div className="col-all justify-end">
                <button type="submit" className="mt-l btn-l btn-fill">Submit Application</button>
              </div>
            </form>
          ) : (
            <form onSubmit={handleCompanySubmit} className="flex-column gap-column-l gap-row-m grid-2">
              <fieldset>
                <label htmlFor="name">Name</label>
                <input
                  placeholder="Name"
                  type="text"
                  id="name"
                  name="name"
                  value={formCompanyData.name}
                  onChange={handleCompanyInputChange}
                  required
                />
              </fieldset>
              <fieldset>
                <label htmlFor="city">City</label>
                <input
                  placeholder="City"
                  type="text"
                  id="city"
                  name="city"
                  value={formCompanyData.city}
                  onChange={handleCompanyInputChange}
                  required
                />
              </fieldset>
              <fieldset>
                <label htmlFor="vat">Vat</label>
                <input
                  placeholder="Vat"
                  type="text"
                  id="vat"
                  name="vat"
                  value={formCompanyData.vat}
                  onChange={handleCompanyInputChange}
                  required
                />
              </fieldset>
              <fieldset>
                <label htmlFor="personEmail">Key Person's Email</label>
                <input
                  placeholder="Key Person's Email"
                  type="email"
                  id="personEmail"
                  name="personEmail"
                  value={formCompanyData.personEmail}
                  onChange={handleCompanyInputChange}
                  required
                />
              </fieldset>
              <fieldset>
                <label htmlFor="personName">Key Person's Name</label>
                <input
                  placeholder="Key Person's Name"
                  type="text"
                  id="personName"
                  name="personName"
                  value={formCompanyData.personName}
                  onChange={handleCompanyInputChange}
                  required
                />
              </fieldset>
              <fieldset>
                <label htmlFor="personRole">Key Person's Role</label>
                <input
                  placeholder="Key Person's Role"
                  type="text"
                  id="personRole"
                  name="personRole"
                  value={formCompanyData.personRole}
                  onChange={handleCompanyInputChange}
                  required
                />
              </fieldset>
              <fieldset>
                <label htmlFor="personDepartment">Key Person's Department</label>
                <textarea
                  placeholder="Key Person's Department"
                  id="personDepartment"
                  name="personDepartment"
                  value={formCompanyData.personDepartment}
                  onChange={handleCompanyInputChange}
                  required
                />
              </fieldset>
              <fieldset>
                <label htmlFor="secondName">Second Key Person's Name</label>
                <textarea
                  placeholder="Second Key Person's Name"
                  id="secondName"
                  name="secondName"
                  value={formCompanyData.secondName}
                  onChange={handleCompanyInputChange}
                  required
                />
              </fieldset>

              <fieldset>
                <label htmlFor="secondEmail">Second Key Person's Email</label>
                <textarea
                  placeholder="Second Key Person's Email"
                  id="secondEmail"
                  type="email"
                  name="secondEmail"
                  value={formCompanyData.secondEmail}
                  onChange={handleCompanyInputChange}
                  required
                />
              </fieldset>

              <fieldset>
                <label htmlFor="challenges">Innovation Challenge</label>
                <textarea
                  placeholder="Please describe your business innovation challenge"
                  id="challenges"
                  name="challenges"
                  value={formCompanyData.challenges}
                  onChange={handleCompanyInputChange}
                  required
                />
              </fieldset>

              <fieldset>
                <label htmlFor="startupPilot">Example pilot's results and challenges</label>
                <textarea
                  placeholder="Have you developed any pilot with a Startup partner? Please provide main results and challenges."
                  id="startupPilot"
                  name="startupPilot"
                  value={formCompanyData.startupPilot}
                  onChange={handleCompanyInputChange}
                />
              </fieldset>

              <fieldset>{createDropdown('sector', 'Company\'s Activity Sector', sector, formCompanyData, handleCompanyInputChange)}</fieldset>

              <div className="col-all justify-end">
                <button type="submit" className="mt-l btn-l btn-fill">Submit Application</button>
              </div>
            </form>
          )}
        </div>
      </section>

      <hr className="hr-between-sections"/>
    </>
  );
}

export default ApplyPage;