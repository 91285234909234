import frontendConfig from 'frontendConfig';
import React, { useState, createContext, useContext, useRef, useEffect } from 'react';

const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  const [modal, setModal] = useState(null);
  const modalRef = useRef(null);
  const [lockOnScroll, setLockOnScroll] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && event.target.classList.contains('modal-component')) {
        handleClose();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };

  }, [modalRef]);

  const scrollHandlerRef = useRef(null); // Declare scrollHandlerRef here

  useEffect(() => {
    const handleScroll = (e) => {
      if (lockOnScroll) {
        e.preventDefault();
      }
    };

    scrollHandlerRef.current = handleScroll;

    if (lockOnScroll) {
      window.addEventListener('wheel', scrollHandlerRef.current, { passive: false });
      document.body.style.overflow = 'hidden';
    }

    return () => {
      window.removeEventListener('wheel', scrollHandlerRef.current);
      document.body.style.overflowY = 'unset';
      document.body.style.overflowX = 'hidden';
    };
  }, [lockOnScroll]);

  const handleClose = () => {
    let dimmer = document.querySelector('.standard-modal-box-dimmer');
    let holder = document.querySelector('.standard-modal-box-holder');

    if (dimmer && holder) {
      holder.classList.add("removing");
      dimmer.classList.remove("active");
      holder.classList.remove("active");
      setTimeout(function () {
        dimmer.style.display = 'none';
        holder.style.display = 'none';
        setLockOnScroll(false)
        setModal(null);
      }, 675);
    }
  };

  const handleShow = (modalType, props) => {
    const modalConfig = frontendConfig.modals[modalType];
    setLockOnScroll(frontendConfig.modals[modalType].scrollLock)

    const propsToPass = { ...props, handleClose };

    setTimeout(function () {
      let dimmer = document.querySelector('.standard-modal-box-dimmer');
      let holder = document.querySelector('.standard-modal-box-holder');

      dimmer.style.display = "flex";
      holder.style.display = "flex";
      setTimeout(function () {
        dimmer.classList.add("active");
        holder.classList.add("active");
        holder.setAttribute('tabindex', '-1');
        holder.focus();
      }, 25);
    }, 25);
    setModal({ type: modalType, props: propsToPass, config: modalConfig });
  };

  return (
    <ModalContext.Provider value={{ modal, handleShow, handleClose, modalRef }}>
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = () => useContext(ModalContext);

export const GlobalModal = () => {
  const { modal, handleClose, modalRef } = useModal();


  if (!modal) {
    return null;
  }

  const { type, props, config } = modal;
  console.log(props)
  const { modalId, showCloseButton, wrapContent, wrapClass, component: ModalComponent } = config;

  return (
    <div className="modal-component-container">
      <div className={`standard-modal-box-dimmer ${config.dimmerActiveClass || ''}`}></div>
      <div className={`standard-modal-box-holder ${config.holderActiveClass || ''}`}>
        <div className="modal-component" ref={modalRef}>
          <div className={`standard-modal-box ${wrapClass || ''}`} id={modalId || ''}>
            {showCloseButton && (
              <button type='button' className="btn-close-modal" onClick={handleClose}>
                <svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" x="0" y="0" style={{ enableBackground: "new 0 0 24 24" }} version="1.1" viewBox="0 0 24 24">
                  <path d="M4.93 19.07 19.07 4.93M19.07 19.07 4.93 4.93" />
                </svg>
              </button>
            )}
            {wrapContent ? <div className="content"><ModalComponent {...props} /></div> : <ModalComponent {...props} />}
          </div>
        </div>
      </div>
    </div>
  );


};