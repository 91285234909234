export const generateFetchActionReducers = (actionType) => ({
    pending: (state) => {
        state.status = 'loading';
    },
    fulfilled: (state, action) => {
        state.status = 'succeeded';
        const actionType = action.type.replace('/fulfilled', '');
        const isPostAction = actionType.endsWith('post');

        console.log('Action Type:', actionType);
        console.log('Is POST Action:', isPostAction);
        console.log('Payload:', action.payload);
    
        if (isPostAction) {
            console.log('Handling POST Action:', action.payload);
        } else {
            state.data[action.payload.key] = action.payload.data;
        }
    },
    rejected: (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
    },
});

export const generateExtraReducers = (fetchActions) => (builder) => {
    fetchActions.forEach(({ action, reducers }) => {
        if (typeof action === 'function') {
            // Handling non-nested thunks
            Object.entries(reducers).forEach(([key, reducer]) => {
                builder.addCase(action[key], reducer);
            });
        } else {
            // Handling nested thunks (for multiple methods)
            Object.keys(action).forEach(methodKey => {
                const methodAction = action[methodKey];
                Object.entries(reducers).forEach(([key, reducer]) => {
                    if (methodAction[key]) {
                        builder.addCase(methodAction[key], reducer);
                    }
                });
            });
        }
    });
};

export const createDropdown = (name, options, formData,handleInputChange) => (
    <select name={name} value={formData[name]} onChange={handleInputChange}>
      {options.map(option => (
        <option key={option} value={option}>{option}</option>
      ))}
    </select>
);
