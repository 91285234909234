import { asyncActions } from 'controller/BaseSlice';
import { useFullScreenOverlay } from 'controller/hooks/useFullScreenOverlay';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

function ContactsPage() {
  const dispatch = useDispatch();
  const { showOverlay, hideOverlay } = useFullScreenOverlay();

  const [formData, setFormData] = useState({
      name: '',
      // lastName: '',
      email: '',
      company: '',
      message: '',
      checked: '',
  });

  const handleChange = (e) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleContactApplication = async (contactApplication) => {
    const formData = new FormData();
    Object.entries(contactApplication).forEach(([key, value]) => {
      formData.append(key, value);
    });

    showOverlay();
    toast.promise(
      dispatch(asyncActions.contacts(formData)).unwrap(),
      {
        pending: 'Sending message...',
        success: 'Message sent! Well get back to you as soon as possible',
        error: 'Something went wrong. Please try again!'
      }
    ).finally(() => {
      setFormData({
        name: '',
        // lastName: '',
        email: '',
        company: '',
        message: '',
        checked: '',
    });
      hideOverlay();
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    handleContactApplication(formData);
  };


  return (
    <>
      {/* <section className="inner-page-header">
        <img src={HeaderImg}/>
        <div className="container">
          <div className="section-title">
            <h2>Contact the Team</h2>
            <p>Remember no question is too small. Ask away and we’ll get back to you as soon as possible.</p>
          </div>
        </div>
      </section> */}

      <hr className="hr-header-spacing"/>

      <section>
        <div className="container">
          <div className="grid-2 grid-1-on-breakpoint-sm gap-xl gap-l-on-breakpoint-m">
            <div className="section-title">
              <div className="sub-title"><p>No question is too small</p></div>
              <h1>Contact the Team</h1>
              <p className="text-l text-ml-on-breakpoint-xxl">Ask away and we’ll get back to you as soon as possible.</p>
            </div>
            <form className="flex-column gap-m gap-s-on-breakpoint-m" onSubmit={handleSubmit}>
              <fieldset>
                <label>Name</label>
                <input
                  placeholder="Name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
              </fieldset>
              {/* <fieldset>
                <input
                  placeholder="Last Name"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                />
              </fieldset> */}
              <fieldset>
                <label>Email</label>
                <input
                  type='email'
                  placeholder="Email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </fieldset>
              <fieldset>
                <label>Company</label>
                <input
                  placeholder="Company"
                  name="company"
                  value={formData.company}
                  onChange={handleChange}
                />
              </fieldset>
              <fieldset className="col-2">
                <label>Message</label>
                <textarea
                  placeholder="Message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                ></textarea>
              </fieldset>
              <hr className="hr-m" />
              <div className="justify-end">
                <button type="submit" className="btn-l btn-ghost"><span>Submit</span></button>
              </div>
            </form>
          </div>
        </div>
      </section>

      <hr className="hr-between-sections"/>
    </>
  );
}

export default ContactsPage;