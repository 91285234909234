import { asyncActions } from 'controller/BaseSlice';
import frontendConfig from 'frontendConfig';
import { useModal } from 'providers/ModalContext';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import LoadingComponent from '../components/LoadingComponent';

import HomeImage1 from 'render/assets/media/auxiliary/startups-and-companies-converge-2--matchbox.jpg';
import HomeIntroImage from 'render/assets/media/auxiliary/home--matchbox.jpg';
import StartupsProfileIcon1 from 'render/assets/media/auxiliary/startup-profile-prototype--matchbox.png';
import StartupsProfileIcon2 from 'render/assets/media/auxiliary/startup-profile-team--matchbox.png';
import StartupsProfileIcon3 from 'render/assets/media/auxiliary/startup-profile-commited--matchbox.png';
import Arrow from 'render/assets/media/auxiliary/arrow.svg';

function HomePage() {

  const companiesData = useSelector(state => state.base.data.companies);
  const challengesData = useSelector(state => state.base.data.challenges);

  const dispatch = useDispatch();

  const { handleShow } = useModal();

  useEffect(() => {
    dispatch(asyncActions.companies.get());
    dispatch(asyncActions.challenges());
  }, [dispatch]);

  if (!companiesData || !challengesData)
    return <LoadingComponent></LoadingComponent>

  return (
    <>
      <section id="home-section-1">
        <div className="container">
          <div>
            <h1>Be part of <span>reshaping</span> industries for the future, <span>together</span>.</h1>
            {/* <div className="image-container">
              <img src={HomeIntroImage}/>
            </div> */}
            <div>
              <Link to={frontendConfig.routes.APPLY} className="btn-l btn-ghost">Apply</Link>
            </div>
          </div>
        </div>
      </section>

      <hr className="hr-between-sections" />

      <section id="home-section-2">
        <div className="container">
          <div className="section-title">
            <h2>Where Startups<br />& Industries Converge</h2>
          </div>
          <div className="info-and-image-item">
            <div className="info-and-image-item--info">
              <p>Our program serves as the nexus where the agility of startups merges seamlessly with the stability of traditional industries, propelling innovation at an unmatched pace. We facilitate collaborations that expedite product development, market penetration, and transformative growth for both startups and established businesses</p>
              <ul className="list-item">
                <li><ScrollLink activeClass="active" to="home-section-3" spy={true} smooth={true} offset={-70} duration={500}><span>Startup Profile</span><img src={Arrow} /></ScrollLink></li>
                <li><ScrollLink activeClass="active" to="home-section-4" spy={true} smooth={true} offset={-70} duration={500}><span>The Challenges</span><img src={Arrow} /></ScrollLink></li>
                <li><ScrollLink activeClass="active" to="home-section-5" spy={true} smooth={true} offset={-70} duration={500}><span>Industry Partners</span><img src={Arrow} /></ScrollLink></li>
              </ul>
            </div>
            <div className="info-and-image-item--image">
              <img src={HomeImage1} />
            </div>
          </div>
        </div>
      </section>

      <hr className="hr-between-sections" />

      <section className="gradient-along-sections">

        <section id="home-section-3">
          <div className="container">
            <div className="section-title">
              <div className="sub-title"><h3>Startups Profile</h3></div>
              <h2>Who are we looking for?</h2>
            </div>
            <ul className="list-cards">
              <li>
                <p>Have a minimum viable product or prototype to be tested</p>
                <img src={StartupsProfileIcon1} />
              </li>
              <li>
                <p>Fulltime team, with at least one of the co-founders working full time</p>
                <img src={StartupsProfileIcon2} />
              </li>
              <li>
                <p>Committed to participate  in all remote and in-person moments of the program</p>
                <img src={StartupsProfileIcon3} />
              </li>
            </ul>
            <div className="cta-container mt-0">
              <Link className="btn-l btn-ghost" to={frontendConfig.routes.APPLY}>Apply</Link>
            </div>
          </div>
        </section>

        <hr className="hr-between-sections" />

        <section id="home-section-4">
          <div className="container">
            <div className="section-title">
              <div className="sub-title"><h3>What companies need</h3></div>
              <h2>Challenges</h2>
            </div>
            <ul className="list-cards" counter="false">
              {challengesData && Object.entries(challengesData).map(([key, challenge], index) => (
                <li key={index}>
                  <h4>{challenge.company}</h4>
                  <p>{challenge.challenges}</p>
                </li>
              ))}
            </ul>
            <div className="cta-container mt-0">
              <Link className="btn-l btn-ghost" to={frontendConfig.routes.APPLY}>Apply</Link>
            </div>
          </div>
        </section>

        <hr className="hr-between-sections" />

        <section id="home-section-5">
          <div className="container">
            <div className="section-title">
              <div className="sub-title"><h3>Our</h3></div>
              <h2>Industry Partners</h2>
              <p>Meet the pioneering companies at the forefront of our Open Innovation Program. Industry leaders that embody the spirit of transformative collaboration. Explore our network of visionaries shaping industries and driving unparalleled innovation into the future.</p>
            </div>
            <ul className="list-logos">
              {companiesData && Object.entries(companiesData).map(([key, value], index) => (
                <li key={index}>
                  <h3>{value.name}</h3>
                </li>
              ))}
            </ul>
          </div>
        </section>
      </section>

      {/* <button onClick={() => handleShow(frontendConfig.modals.default.modalId)}>Open Modal</button> */}
    </>
  );

}

export default HomePage
