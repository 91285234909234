import frontendConfig from 'frontendConfig';
import React from 'react';
import { Link } from 'react-router-dom';

import HeaderImg from 'render/assets/media/auxiliary/startups-and-companies-converge--matchbox.jpg';

function AboutPage() {

  return (
    <>
      <section className="inner-page-header">
        <img src={HeaderImg}/>
        {/* <div className="container text-black">
          <div className="section-title">
            <h2>Welcome to Matchbox</h2>
            <h1>Where Innovation<br/>Finds its Perfect Match</h1>
            <p>Matchbox is an Open Innovation Program where we orchestrate the perfect duet between corporations and startups, turning ideas into impactful collaborations. Our program is the intersection of proactive matchmaking, strategic piloting, and insightful technology assessments.</p>
            <div>
              <Link className="btn btn-fill" to={frontendConfig.routes.APPLY}>Apply</Link>
            </div>
          </div>
        </div> */}
      </section>

      {/* <hr className="hr-header-spacing"/>

      <section id="about-section-1">
        <div className="container">
          <div className="info-and-image-item">
            <div className="info-and-image-item--info">
              <h1>Welcome to Matchbox</h1>
              <h3>Where Innovation Finds its Perfect Match</h3>
              <p>Matchbox is an Open Innovation Program where we orchestrate the perfect duet between corporations and startups, turning ideas into impactful collaborations. Our program is the intersection of proactive matchmaking, strategic piloting, and insightful technology assessments.</p>
            </div>
            <div className="info-and-image-item--image">
              <img src={AboutImage1}/>
            </div>
          </div>
        </div>
      </section> */}

      <hr className="hr-between-sections"/>

      <section>
        <div className="container text-black">
          <div className="section-title">
            <div className="sub-title"><h3>Welcome to Matchbox</h3></div>
            <h2>Where Innovation<br/>Finds its Perfect Match</h2>
            <p>Matchbox is an Open Innovation Program where we orchestrate the perfect duet between corporations and startups, turning ideas into impactful collaborations. Our program is the intersection of proactive matchmaking, strategic piloting, and insightful technology assessments.</p>
          </div>
          <div className="cta-container mt-0">
            <Link className="btn-l btn-ghost" to={frontendConfig.routes.APPLY}>Apply</Link>
          </div>
        </div>
      </section>

      <hr className="hr-between-sections"/>

      <section>
        <div className="container">
          <div className="section-title">
            <h1>Dive into a world where <span className="text-primary">innovation is not just a goal.</span></h1>
          </div>
          <ul className="list-features">
            <li>
              <h4>Navigating the Innovation Landscape</h4>
              <p>Our journey doesn't stop at matchmaking; it extends to networking at industry events, engaging with innovation scouts, and participating in collaborative workshops. Matchbox creates the stage where corporations and startups meet, exchange ideas, and embark on a collaborative journey.</p>
            </li>
            <li>
              <h4>Piloting Success Stories</h4>
              <p>Define clear objectives, explore synergies, and discuss success metrics in our meticulously planned piloting sessions. Address concerns, propose pilot projects, and lay the groundwork for fruitful collaborations. We guide you through legal and financial frameworks, ensuring a solid foundation for future endeavors.</p>
            </li>
            <li>
              <h4>Technology Assessment Excellence</h4>
              <p>Ignite innovation with an unbiased, expert assessment from "Igniteers." Our third-party evaluations bring specialized knowledge, objectivity, and credibility to the table. Mitigate risks, verify claims, and uncover areas for improvement as you march towards a future-ready collaboration.</p>
            </li>
            <li>
              <h4>Crafting Lasting Partnerships</h4>
              <p>Matchbox is not just a program; it's a commitment to fostering ongoing communication, trust, and growth. We believe in continuous relationship-building, where each interaction contributes to a stronger network of potential partners.</p>
            </li>
          </ul>
        </div>
      </section>

      <hr className="hr-between-sections"/>

      <section>
        <div className="container">
          <div className="section-title">
            <div className="sub-title"><h3>Join us at Matchbox</h3></div>
            <h1>Where <span className="text-primary">innovation</span><br/>meets <span className="text-primary">collaboration</span>,<br/>and partnerships<br/><span className="text-primary">transcend</span> expectations.</h1>
          </div>
          <div className="cta-container mt-0">
            <Link className="btn-l btn-ghost" to={frontendConfig.routes.APPLY}>Apply</Link>
          </div>
        </div>
      </section>
    </>
  );

}

export default AboutPage
