import frontendConfig from 'frontendConfig';
import React from 'react';
import { Link } from 'react-router-dom';

import Logo from 'render/assets/media/brand/logo-matchbox-2.svg';
// import ContactFooterImage from 'render/assets/media/auxiliary/reshaping-industries--matchbox.jpg';

function FooterComponent() {

  return (
    <>
      {/* <footer id="contacts-footer">
        <img src={ContactFooterImage}/>
        <div className="container">
          <Link to={frontendConfig.routes.CONTACTS} className="btn-l btn-fill">Contact Us</Link>
        </div>
      </footer> */}
      <footer id="main-footer">
        <div className="container">
          <div className="cta-container">
              <Link className="btn-l btn-ghost" to={frontendConfig.routes.CONTACTS}>Contact Us</Link>
          </div>
          <div id="main-footer--main-info">
            <div id="main-footer--logo-container">
              <Link to={frontendConfig.routes.HOME}><img src={Logo} alt="" /></Link>
            </div>
            <div>
              <h2>Be part of reshaping<br/>industries for the<br/>future, together.</h2>
              <nav id="main-footer--main-nav">
                <Link to={frontendConfig.routes.ABOUT}>About</Link>
                <Link to={frontendConfig.routes.CONTACTS}>Contacts</Link>
                <Link to={frontendConfig.routes.APPLY}>Apply</Link>
                <Link to={frontendConfig.routes.TERMS}>Terms and Conditions</Link>
              </nav>
            </div>
          </div>
          <div className="justify-center">
            <p className="text-s text-xs-on-breakpoint-s">Copyright © Startup Portugal. All Rights Reserved</p>
          </div>
        </div>
      </footer>
    </>
  );

}

export default FooterComponent
