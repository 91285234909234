import { createContext, useContext, useState, useCallback } from 'react';

const FullScreenOverlayContext = createContext();

export const useFullScreenOverlay = () => useContext(FullScreenOverlayContext);

export const FullScreenOverlayProvider = ({ children }) => {
    const [isOverlayVisible, setIsOverlayVisible] = useState(false);
    const [overlayMessage, setOverlayMessage] = useState('');
  
    const showOverlay = useCallback((message = '') => {
      setOverlayMessage(message);
      setIsOverlayVisible(true);
    }, []);
    const hideOverlay = useCallback(() => setIsOverlayVisible(false), []);
  
    return (
      <FullScreenOverlayContext.Provider value={{ showOverlay, hideOverlay }}>
        {children}
        {isOverlayVisible && <FullScreenOverlay message={overlayMessage} />}
      </FullScreenOverlayContext.Provider>
    );
  };

const FullScreenOverlay = ({ message }) => (
    <div className="loading-container" type="overlay">
        <div className="loading-spinner"></div>
        <h3 className="loading-text">{message}</h3>
    </div>
);
