// frontendConfig.js
import DefaultModal from "render/views/modals/DefaultModal";

const frontendConfig = {

    clientName: 'Frontend',

    api: {
        baseUrl: 'https://matchbox.startupportugal.com:3001',
        // baseUrl: 'http://localhost:3001',
    
        endpoints: {
            login: { url: "login", method: 'POST' },
            companies: {
                url: "companies",
                methods: {
                    get: { method: 'GET' },
                    post: { method: 'POST' }
                }
            },
            startups: {
                url: "startups",
                methods: {
                    get: { method: 'GET' },
                    post: { method: 'POST' }
                }
            },
            challenges: { url: "challenges", method: 'GET' },
            checkToken: { url: "check-token", method: 'GET' },
            contacts: { url: "contacts", method: 'POST' },
        },
    },

    modals: {
        default: {
            modalId: "default",
            showCloseButton: false,
            wrapContent: false,
            wrapClass: "default-modal-class",
            scrollLock: true,
            component: DefaultModal
        },
    },

    routes:{
        HOME: '/',
        ABOUT: '/about',
        AGENDA: '/agenda',
        CHALLENGES: '/challenges',
        ECOSYSTEM_PARTNERS: '/ecosystem-partners',
        STARTUPS: '/startups',
        COMPANIES: '/companies',
        APPLY: '/apply',
        APPLY_STARTUPS: '/apply-startups',
        APPLY_COMPANIES: '/apply-companies',
        CONTACTS: '/contacts',
        TERMS: '/terms',
      },

};

export default frontendConfig;

