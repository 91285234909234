import axios from 'axios'
import frontendConfig from 'frontendConfig';
import { toast } from 'react-toastify';

export const getReturnData = async (endpoint) => {
    try {
        const response = await axios.get(frontendConfig.api.baseUrl+"/"+endpoint);
        return response.data;
    } catch (error) {
        console.log(error)
        toast.error(`Error:  ${error}`);
    }
};

export const postData = async (endpoint,data) => {
    try {
        const response = await axios.post(frontendConfig.api.baseUrl+"/"+endpoint, data);
        return response.data;
      } catch (error) {
        console.error('Error making POST request:', error);
        throw error;
      }
};