import React from 'react';

const DefaultModal = ( { handleClose }) => {

    return (
        <div onClick={()=>handleClose()}>
            <h1>MODAL DEFAULT</h1>
        </div>
    );
};

export default DefaultModal;
