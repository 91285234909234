import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { asyncActions } from 'controller/BaseSlice';
import frontendConfig from 'frontendConfig';
import { Link } from 'react-router-dom';
import LoadingComponent from '../components/LoadingComponent';

import HeaderImg from 'render/assets/media/auxiliary/challenges--matchbox.jpg';

function ChallengesPage() {
  const dispatch = useDispatch();
  const challengesData = useSelector(state => state.base.data.challenges);

  useEffect(() => {
      dispatch(asyncActions.challenges());
  }, [dispatch]);

  if (!challengesData)
    return <LoadingComponent></LoadingComponent>

  return (
    <>
      <section className="inner-page-header">
        <img src={HeaderImg}/>
        {/* <div className="container">
          <div className="section-title">
            <h1>Challenges</h1>
            <p>Check out all our available industry challenges and if you feel you have the right solution to solve it, apply!</p>
            <div>
              <Link className="btn btn-fill" to={frontendConfig.routes.APPLY}>Apply</Link>
            </div>
          </div>
        </div> */}
      </section>

      <section>
        <hr className="hr-between-sections"/>
        <div className="container">
          <div className="section-title">
            <div className="sub-title"><h3>What companies need</h3></div>
            <h2>Challenges</h2>
            <p>Check out all our available industry challenges and if you feel you have the right solution to solve it, apply!</p>
          </div>
          <ul className="list-cards" type="2" counter="false">
            {challengesData && Object.entries(challengesData).map(([key, challenge], index) => (
              <li key={index}>
                <p>{challenge.challenges}</p>
                <div>
                  <h6 className="text-gray">Companies with this challenge:</h6>
                  <h5>{challenge.company}</h5>
                </div>
              </li>
            ))}
          </ul>
          <div className="cta-container mt-0">
            <Link className="btn-l btn-ghost" to={frontendConfig.routes.APPLY}>Apply</Link>
          </div>
        </div>


        {/* <div className="container">
          <ul className="list-cards" type="2" counter="false">
            {challengesData && Object.entries(challengesData).map(([key, challenge], index) => (
              <li key={index}>
                <p>{challenge.challenges}</p>
                <div>
                  <h6 className="text-gray">Companies with this challenge:</h6>
                  <h5>{challenge.company}</h5>
                </div>
              </li>
            ))}
          </ul>
          <hr className="hr-xxl"/>
          <div className="cta-container">
            <Link className="btn btn-ghost" to={frontendConfig.routes.APPLY}>Apply</Link>
          </div>
        </div> */}
      </section>

      <hr className="hr-between-sections"/>
    </>
  );
}

export default ChallengesPage