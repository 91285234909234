import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as api from 'controller/api/API';
import frontendConfig from 'frontendConfig';
import { generateFetchActionReducers, generateExtraReducers } from 'controller/scripts/Utils';

const initialState = {
    data: {},
    status: 'idle',
    error: null,
    isBaseFetched: {}
};

const createAsyncThunks = (endpoints) => {
    let thunks = {};
    Object.keys(endpoints).forEach(key => {
        const endpoint = endpoints[key];
        if (endpoint.methods) {
            thunks[key] = {};
            Object.keys(endpoint.methods).forEach(methodKey => {
                const { method } = endpoint.methods[methodKey];
                thunks[key][methodKey] = createAsyncThunk(
                    `base/${key}/${methodKey}`,
                    async (payload, { rejectWithValue }) => {
                        try {
                            let response;
                            if (method === 'GET') {
                                response = await api.getReturnData(endpoint.url);
                            } else if (method === 'POST') {
                                response = await api.postData(endpoint.url, payload);
                            }
                            return { key, data: response };
                        } catch (error) {
                            return rejectWithValue(error.response.data);
                        }
                    }
                );
            });
        } else {
            const { url, method } = endpoint;
            thunks[key] = createAsyncThunk(
                `base/${key}`,
                async (payload, { rejectWithValue }) => {
                    try {
                        let response;
                        if (method === 'GET') {
                            response = await api.getReturnData(url);
                        } else if (method === 'POST') {
                            response = await api.postData(url, payload);
                        }
                        return { key, data: response };
                    } catch (error) {
                        return rejectWithValue(error.response.data);
                    }
                }
            );
        }
    });
    return thunks;
};

const asyncThunks = createAsyncThunks(frontendConfig.api.endpoints);

const fetchActions = Object.keys(frontendConfig.api.endpoints).map(key => ({
    action: asyncThunks[key],
    reducers: generateFetchActionReducers(key),
}));

const BaseSlice = createSlice({
    name: 'base',
    initialState,
    reducers: {
        setBaseFetched: (state, action) => {
            const { key, value } = action.payload;
            state.isBaseFetched[key] = value;
        },
    },
    extraReducers: generateExtraReducers(fetchActions),
});

export const { setBaseFetched } = BaseSlice.actions;

export default BaseSlice.reducer;

export const asyncActions = asyncThunks;