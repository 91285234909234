import { asyncActions } from 'controller/BaseSlice';
import frontendConfig from 'frontendConfig';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import LoadingComponent from '../components/LoadingComponent';

import HeaderImg from 'render/assets/media/auxiliary/companies--matchbox.jpg';
import CompaniesProfileIcon1 from 'render/assets/media/auxiliary/company-profile-innovative--matchbox.png';
import CompaniesProfileIcon2 from 'render/assets/media/auxiliary/company-profile-colab--matchbox.png';
import CompaniesProfileIcon3 from 'render/assets/media/auxiliary/company-profile-synergy--matchbox.png';
import CompaniesProfileIcon4 from 'render/assets/media/auxiliary/company-profile-innovation--matchbox.png';

function CompaniesPage() {

  const companiesData = useSelector(state => state.base.data.companies);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(asyncActions.companies.get());
  }, [dispatch]);

  if (!companiesData)
    return <LoadingComponent></LoadingComponent>

  return (
    <>
      <section className="inner-page-header">
        <img src={HeaderImg}/>
        {/* <div className="container">
          <div className="section-title">
            <h1>Companies</h1>
            <h3 className="text-primary">Unveiling Opportunities Through Proactive Matchmaking</h3>
            <p>Corporations, gear up for a transformative experience! Matchbox is your gateway to identifying, embracing, and integrating external innovation. Whether you're seeking cutting-edge technology, market expansion, process enhancements, or product diversification, our specialized matchmaking platforms are the compass guiding you to your ideal startup partner. Update your profile, articulate your needs, and let the algorithms orchestrate the perfect match.</p>
            <div>
              <Link className="btn btn-fill" to={frontendConfig.routes.APPLY}>Apply</Link>
            </div>
          </div>
        </div> */}
      </section>

      <hr className="hr-between-sections"/>

      <section>
        <div className="container">
          <div className="section-title">
            <div className="sub-title"><h1>Companies</h1></div>
            <h3 className="text-primary">Unveiling Opportunities Through Proactive Matchmaking</h3>
            <p>Corporations, gear up for a transformative experience! Matchbox is your gateway to identifying, embracing, and integrating external innovation. Whether you're seeking cutting-edge technology, market expansion, process enhancements, or product diversification, our specialized matchmaking platforms are the compass guiding you to your ideal startup partner. Update your profile, articulate your needs, and let the algorithms orchestrate the perfect match.</p>
          </div>
          <div className="cta-container mt-0">
            <Link className="btn-l btn-ghost" to={frontendConfig.routes.APPLY}>Apply</Link>
          </div>
        </div>
      </section>

      <hr className="hr-between-sections"/>

      <section>
        <div className="container">
          <div className="section-title">
            <div className="sub-title"><h3>Companies Profile</h3></div>
            <h2>Who are we looking for?</h2>
          </div>
          <ul className="list-cards" type="companies">
            <li>
              <p>Accelerate your company's innovation trajectory by leveraging diverse perspectives, new methodologies, and collaborative opportunities inherent in open innovation programs.</p>
              <img src={CompaniesProfileIcon1}/>
            </li>
            <li>
              <p>Foster Collaboration with Startups</p>
              <img src={CompaniesProfileIcon2}/>
            </li>
            <li>
              <p>Engage with startups and emerging businesses, fostering symbiotic relationships that allow for mutual learning, innovation, and potential future collaborations.</p>
              <img src={CompaniesProfileIcon3}/>
            </li>
            <li>
              <p>Gain exposure to emerging market trends, enabling your company to identify and capitalize on new business opportunities within evolving industry landscapes.</p>
              <img src={CompaniesProfileIcon4}/>
            </li>
          </ul>
          <div className="cta-container mt-0">
            <Link className="btn-l btn-ghost" to={frontendConfig.routes.APPLY}>Apply</Link>
          </div>
        </div>
      </section>

      <hr className="hr-between-sections"/>

      <section id="home-section-5">
        <div className="container">
          <div className="section-title">
            <div className="sub-title"><h3>Our</h3></div>
            <h2>Industry Partners</h2>
            <p>Meet the pioneering companies at the forefront of our Open Innovation Program. Industry leaders that embody the spirit of transformative collaboration. Explore our network of visionaries shaping industries and driving unparalleled innovation into the future.</p>
          </div>
          <ul className="list-logos">
            {companiesData && Object.entries(companiesData).map(([key, value], index) => (
              <li key={index}>
                <h4>{value.name}</h4>
              </li>
            ))}
          </ul>
        </div>
      </section>

      <hr className="hr-between-sections"/>
    </>
  );

}

export default CompaniesPage
