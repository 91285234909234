import { Provider } from 'react-redux';
import ReduxStore from 'controller/ReduxStore';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from 'locales/en/translation.json';
import translationPT from 'locales/pt/translation.json';
import { GlobalModal, ModalProvider } from './ModalContext';

const resources = {
  en: {
    translation: translationEN
  },
  pt: {
    translation: translationPT
  }
};

// Initialize i18n
i18n
  .use(initReactI18next) // Passes i18n down to react-i18next
  .init({
    resources, // Comment this if resources are not used
    lng: 'en', // Default language
    fallbackLng: 'en', // Use English if the detected language is not available
    interpolation: {
      escapeValue: false, // React already escapes values, so this is not needed
    },
  });

export const MainProvider = (props) => (
  <>
    <Provider store={ReduxStore}>
      <ModalProvider>
        <GlobalModal />
        {props.children}
      </ModalProvider>
    </Provider>
    <ToastContainer />
  </>

);
