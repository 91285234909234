import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from 'render/views/pages/HomePage';
import TermsPage from 'render/views/pages/TermsPage';
import PageNotFound from 'render/views/pages/PageNotFound';
import frontendConfig from 'frontendConfig';
import AboutPage from 'render/views/pages/AboutPage';
import CompaniesPage from 'render/views/pages/CompaniesPage';
import StartupsPage from 'render/views/pages/StartupsPage';
import ApplyPage from 'render/views/pages/ApplyPage';
import ContactsPage from 'render/views/pages/ContactsPage';
// import AgendaPage from 'render/views/pages/AgendaPage';
import ChallengesPage from 'render/views/pages/ChallengesPage';
import EcosystemPartnersPage from 'render/views/pages/EcosystemPartnersPage';

const RoutesFile = () => {

  return (
    <Routes>
      <Route
        path={frontendConfig.routes.HOME}
        element={<HomePage />}
        exact
      />

      <Route
        path={frontendConfig.routes.ABOUT}
        element={<AboutPage />}
        exact
      />

      <Route
        path={frontendConfig.routes.COMPANIES}
        element={<CompaniesPage />}
        exact
      />

      <Route
        path={frontendConfig.routes.STARTUPS}
        element={<StartupsPage />}
        exact
      />

      {/* <Route
        path={frontendConfig.routes.AGENDA}
        element={<AgendaPage />}
        exact
      /> */}

      <Route
        path={frontendConfig.routes.CHALLENGES}
        element={<ChallengesPage />}
        exact
      />

      <Route
        path={frontendConfig.routes.ECOSYSTEM_PARTNERS}
        element={<EcosystemPartnersPage />}
        exact
      />

      <Route
        path={frontendConfig.routes.APPLY}
        element={<ApplyPage />}
        exact
      />

      <Route
        path={frontendConfig.routes.CONTACTS}
        element={<ContactsPage />}
        exact
      />

      <Route
        path={frontendConfig.routes.TERMS}
        element={<TermsPage />}
      />
      <Route
        path="*"
        element={<PageNotFound />} />

    </Routes>
  )
};

export default RoutesFile;
