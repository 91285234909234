import { asyncActions } from 'controller/BaseSlice';
import frontendConfig from 'frontendConfig';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import LoadingComponent from '../components/LoadingComponent';

import HeaderImg from 'render/assets/media/auxiliary/startups--matchbox.jpg';
import StartupsProfileIcon1 from 'render/assets/media/auxiliary/startup-profile-prototype--matchbox.png';
import StartupsProfileIcon2 from 'render/assets/media/auxiliary/startup-profile-team--matchbox.png';
import StartupsProfileIcon3 from 'render/assets/media/auxiliary/startup-profile-commited--matchbox.png';

function StartupsPage() {
  const dispatch = useDispatch();

  const startupsData = useSelector(state => state.base.data.startups);

  useEffect(() => {
    dispatch(asyncActions.startups.get());
  }, [dispatch]);

  if (!startupsData)
    return <LoadingComponent></LoadingComponent>

  return (
    <>
      <section className="inner-page-header">
        <img src={HeaderImg}/>
        {/* <div className="container">
          <div className="section-title">
            <h1>Startups</h1>
            <h3 className="text-primary">Step into the spotlight!</h3>
            <p>Matchbox is your stage to showcase your unique offerings, technological prowess, and aspirations. Navigate the corporate landscape with confidence, knowing you're supported in areas like market access, funding, and operational scaling.</p>
            <div>
              <Link className="btn btn-fill" to={frontendConfig.routes.APPLY}>Apply</Link>
            </div>
          </div>
        </div> */}
      </section>

      <hr className="hr-between-sections"/>

      <section>
        <div className="container">
          <div className="section-title">
            <div className="sub-title"><h1>Startups</h1></div>
            <h3 className="text-primary">Step into the spotlight!</h3>
            <p>Matchbox is your stage to showcase your unique offerings, technological prowess, and aspirations. Navigate the corporate landscape with confidence, knowing you're supported in areas like market access, funding, and operational scaling.</p>
          </div>
          <div className="cta-container mt-0">
            <Link className="btn-l btn-ghost" to={frontendConfig.routes.APPLY}>Apply</Link>
          </div>
        </div>
      </section>

      <hr className="hr-between-sections"/>

      <section id="home-section-3">
        <div className="container">
          <div className="section-title">
            <div className="sub-title"><h3>Startups Profile</h3></div>
            <h2>Who are we looking for?</h2>
          </div>
          <ul className="list-cards">
            <li>
              <p>Have a minimum viable product or prototype to be tested</p>
              <img src={StartupsProfileIcon1} />
            </li>
            <li>
              <p>Fulltime team, with at least one of the co-founders working full time</p>
              <img src={StartupsProfileIcon2} />
            </li>
            <li>
              <p>Committed to participate  in all remote and in-person moments of the program</p>
              <img src={StartupsProfileIcon3} />
            </li>
          </ul>
          <div className="cta-container">
            <Link className="btn-l btn-ghost" to={frontendConfig.routes.APPLY}>Apply</Link>
          </div>
        </div>
      </section>

      <hr className="hr-between-sections"/>

      <section>
        <div className="container">
          <div className="section-title">
            <h2>Discover the Advantages</h2>
          </div>
          <ul className="list-features" type="startups">
            <li>
              <h4>Elevate Your Solution</h4>
              <p>Craft a daring, client-centric business model primed for product-led growth, amplifying your market impact.</p>
            </li>
            <li>
              <h4>Gain Valuable Feedback & Mentorship</h4>
              <p>Access tailored guidance from seasoned mentors and investors, tailored to your venture's development stage.</p>
            </li>
            <li>
              <h4>Learn Through Action</h4>
              <p>Move beyond traditional workshops; dive into hands-on experiences, learning through action and evolving through practical testing.</p>
            </li>
            <li>
              <h4>Develop a Standout Pitch</h4>
              <p>Transform amassed experience and invaluable feedback into a compelling pitch and presentation that leaves a lasting impression.</p>
            </li>
            <li>
              <h4>Expand Your Ecosystem & Network</h4>
              <p>Forge connections with investors, ecosystem collaborators, and key stakeholders poised to drive your business expansion.</p>
            </li>
            <li>
              <h4>Co-Create and Pilot Solutions</h4>
              <p>Collaborate to co-develop pilot projects, testing and refining your solution in real-world settings, fostering innovation and growth.</p>
            </li>
            <li>
              <h4>Scale Through Corporate Partnerships</h4>
              <p>Forge strategic alliances with corporate partners, propelling your business towards scalable opportunities and market expansion.</p>
            </li>
            <li>
              <h4>Access Expert Guidance</h4>
              <p>Connect with industry experts, gaining insights and advice to steer your venture towards success in the evolving market landscape.</p>
            </li>
          </ul>

          <div className="cta-container mt-0">
            <Link className="btn-l btn-ghost" to={frontendConfig.routes.APPLY}>Apply</Link>
          </div>
        </div>
      </section>

      <hr className="hr-between-sections"/>

      {/* {Object.entries(startupsData).map(([key, startup], index) => (
        <div key={index}>
          <h3>{startup?.name}</h3>
        </div>
      ))} */}
    </>
  );
}

export default StartupsPage;