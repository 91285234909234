import { asyncActions } from 'controller/BaseSlice';
import frontendConfig from 'frontendConfig';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import LoadingComponent from '../components/LoadingComponent';

import Image1 from 'render/assets/media/auxiliary/partners--matchbox.jpg';

function EcosystemPartnersPage() {

  const dispatch = useDispatch();

  const companiesData = useSelector(state => state.base.data.companies);

  useEffect(() => {
    dispatch(asyncActions.companies.get());
  }, [dispatch]);

  if (!companiesData)
    return <LoadingComponent></LoadingComponent>

  return (
    <>
      <hr className="hr-header-spacing"/>

      <section id="about-section-1">
        <div className="container">
          <div className="section-title">
            <div className="sub-title"><h3>Our</h3></div>
            <h2>Industry Partners</h2>
            <p>Meet the pioneering companies at the forefront of our Open Innovation Program. Industry leaders that embody the spirit of transformative collaboration. Explore our network of visionaries shaping industries and driving unparalleled innovation into the future.</p>
          </div>
          <ul className="list-logos">
            {companiesData && Object.entries(companiesData).map(([key, value], index) => (
              <li key={index}>
                <h3>{value.name}</h3>
              </li>
            ))}
          </ul>
        </div>
        {/* <div className="container">
          <div className="info-and-image-item">
            <div className="info-and-image-item--info">
              <h1>Our Industry Partners</h1>
              <p>Meet the pioneering companies at the forefront of our Open Innovation Program. industry leaders that embody the spirit of transformative collaboration. Explore our network of visionaries shaping industries and driving unparalleled innovation into the future."</p>

              <ul className="list-logos" type="tighter">
                {companiesData && Object.entries(companiesData).map(([key, value], index) => (
                  <li key={index}>
                    <h4>{value.name}</h4>
                  </li>
                ))}
              </ul>
            </div>
            <div className="info-and-image-item--image">
              <img src={Image1}/>
            </div>
          </div>
        </div> */}
      </section>

      <hr className="hr-between-sections"/>
    </>
  );

}

export default EcosystemPartnersPage
